<template>
  <Modal v-model="isShow" title="选择用户" class-name="i-modal" width="1000">
    <div class="layout-container">
      <Form
        ref="SearchForm"
        class="search-form-container"
        :model="paramsUser"
        label-position="left"
        :label-width="70"
      >
        <div class="search-form-container__content">
          <Row :gutter="16">
            <Col span="6">
              <FormItem label="用户编号:" prop="UserCode">
                <Input
                  placeholder="请输入用户编号"
                  v-model="paramsUser.UserCode"
                  clearable
                />
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="用户名称:" prop="RealName">
                <Input
                  placeholder="请输入用户名称"
                  v-model="paramsUser.RealName"
                  clearable
                />
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="手机号:" prop="MobilePhone">
                <Input
                  placeholder="请输入手机号"
                  v-model="paramsUser.MobilePhone"
                  clearable
                />
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem :label-width="0">
                <Button
                  type="primary"
                  class="ml2 search-form-container__button"
                  size="small"
                  icon="ios-search"
                  align="bottom"
                  @click="handleSearch"
                  >搜索</Button
                >
                <Button
                  class="ml2 search-form-container__button"
                  size="small"
                  @click="handleReset"
                  >重置</Button
                >
              </FormItem>
            </Col>
          </Row>
        </div>
      </Form>
      <div class="layout-content" style="overflow: hidden" id="layout-content">
        <Row :gutter="16">
          <Col span="18">
            <Table
              height="432"
              ref="tableproduct"
              border
              size="small"
              stripe
              :columns="columns"
              :data="unSelectedRows.DataList"
              :loading="loading"
              @on-sort-change="sortChange"
              @on-selection-change="SelectionClick"
            >
              <template slot="Status" slot-scope="{ row }">
                <Tag
                  type="border"
                  :color="
                    row.Status === 10
                      ? 'success'
                      : row.Status === 20
                      ? 'error'
                      : 'default'
                  "
                >
                  {{ row.StatusCN }}</Tag
                >
              </template>
              <template slot="Action" slot-scope="{ row }">
                <Button
                  size="small"
                  class="ml2"
                  type="success"
                  @click="AddSingeOne(row)"
                  >添加</Button
                >
              </template>
            </Table>
          </Col>
          <Col span="6">
            <div
              :style="'width:100%;border:1px #dddee1 solid;padding:10px;'"
              class="overflow-y"
            >
              <Tag
                v-for="item in selectedRows"
                :key="item.ID"
                :name="item.RealName"
                type="border"
                closable
                @on-close="Clearitem(item)"
                color="default"
                >{{ item.RealName }}</Tag
              >
            </div>
            <!-- <Table ref="table" border size="small" stripe :columns="columns2" :data="CheckMemberList" :loading="loading" :height='tableH'></Table> -->
          </Col>
        </Row>
      </div>
      <div class="layout-footer">
        <div class="layout-flex-sb">
          <div class="layout-flex-item">
            <Row type="flex" justify="space-between" class="mt5">
              <Col span="5">
                <Button
                  type="success"
                  icon="md-add-circle"
                  @click="AddSelection"
                >
                  <span v-if="!WaitDelete">批量添加</span>
                  <span v-else>点这里添加选中行~</span>
                </Button>
              </Col>
              <Col span="19">
                <Page
                  show-total
                  ref="pageComponent"
                  :total="unSelectedRows.TotalCount"
                  :current="paramsUser.Page"
                  show-elevator
                  show-sizer
                  placement="top"
                  @on-page-size-change="ChangePageSize"
                  @on-change="ChangePage"
                ></Page>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button type="primary" @click="SetUserRole" :loading="setLoading">
        {{ !SaveLoading ? "保存" : "保存中..." }}
      </Button>
    </div>
  </Modal>
</template>

<script>
import api from "@/api";
export default {
  name: "role-user-modal",
  data() {
    return {
      isShow: false,
      loading: false,
      obj: {},
      SaveLoading: false,
      WaitDelete: false,
      setLoading: false,
      selection: [],
      paramsUser: {
        roleID: "",
        Page: 1,
        PageSize: 10,
        SortName: "ID",
        SortOrder: "Asc",
        contain: false,
      },
      unSelectedRows: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      columns: [
        { type: "selection", width: 58, align: "center" },
        {
          title: "会员编号",
          key: "ID",
          ellipsis: true,
          width: 102,
          align: "center",
        },
        {
          title: "会员姓名",
          key: "RealName",
          ellipsis: true,
          align: "center",
        },
        {
          title: "手机号",
          key: "MobilePhone",
          ellipsis: true,
          align: "center",
        },
        {
          title: "状态",
          key: "Status",

          slot: "Status",
          width: 100,
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          slot: "Action",
          align: "center",
          minWidth: 60,
          maxWidth: 60,
          fixed: "right",
        },
      ],
      selectedRows: [],
      selectedRowsSource: [],
    };
  },
  methods: {
    async LoadingData() {
      try {
        this.loading = true;
        this.paramsUser.roleID = this.obj.RoleID;
        this.paramsUser.contain = false;
        var response = await api.GetUserListByRole(this.paramsUser);
        if (response.Status === 100) {
          this.unSelectedRows = response.Data;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async SetUserRole() {
      const _this = this;
      try {
        var addlist = [];
        var dellist = [];
        _this.selectedRowsSource.forEach((item) => {
          if (_this.selectedRows.findIndex((i) => i.ID === item.ID) < 0) {
            dellist.push(item.ID);
          }
        });
        _this.selectedRows.forEach((item) => {
          if (_this.selectedRowsSource.findIndex((i) => i.ID === item.id) < 0) {
            addlist.push(item.ID);
          }
        });
        if (addlist.length || dellist.length) {
          _this.setLoading = true;
          const res = await api.SetUserToRole({
            roleID: _this.obj.RoleID,
            addlist: addlist,
            dellist: dellist,
          });
          if (res.Status === 100) {
            _this.$Message.success("操作成功~");
            _this.setLoading = false;
            _this.isShow = false;
            _this.$emit("on-updated");
          } else {
            _this.setLoading = false;
            _this.$Message.error(res.Msg);
          }
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        this.setLoading = false;
      }
    },
    async GetSelectedData() {
      const _this = this;
      try {
        var response = await api.GetUserListByRole({
          roleID: _this.obj.RoleID,
          contain: true,
          PageSize: 500,
          Page: 1,
          SortName: "ID",
          SortOrder: "Asc",
        });
        if (response.Status === 100) {
          _this.selectedRows = response.Data.DataList;
          _this.selectedRowsSource = JSON.parse(
            JSON.stringify(response.Data.DataList)
          );
          _this.loading = false;
        }
      } catch (error) {
        _this.UseCaseOfSelected = response.Data;
        _this.loading = false;
      }
    },

    // 用户选择清除
    Clearitem(Item) {
      const _this = this;
      _this.selectedRows.splice(
        _this.selectedRows.indexOf(
          _this.selectedRows.find((item) => {
            return item.ID === Item.ID;
          })
        ),
        1
      );
      _this.$Message.success("移除成功！");
    },
    AddSingeOne(row) {
      const _this = this;
      if (
        _this.selectedRows.indexOf(
          _this.selectedRows.find((item) => {
            return item.ID === row.ID;
          })
        ) === -1
      ) {
        _this.selectedRows.push(row);
      } else {
        _this.$Message.error("已在列表内！");
      }
    },
    AddSelection() {
      const _this = this;
      if (_this.WaitDelete) {
        _this.selection.map((item) => {
          if (
            _this.selectedRows.indexOf(
              _this.selectedRows.find((cell) => {
                return cell.ID === item.ID;
              })
            ) === -1
          ) {
            _this.selectedRows.push(item);
          } else {
            _this.$Message.error("已在列表内！");
          }
        });
      } else {
        _this.$Message.error("请先选择需要添加的行！");
      }
    },
    handleSearch() {
      this.paramsUser.Page = 1;
      this.LoadingData();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.paramsUser.Page = 1;
      this.LoadingData();
    },
    // 远程排序
    sortChange(result) {
      this.paramsUser.SortName = result.key;
      this.paramsUser.SortOrder =
        this.paramspro.SortOrder === "Desc" ? "Asc" : "Desc";
      this.LoadingData();
    },
    // 选项发生改变方法
    SelectionClick(selection) {
      const _this = this;
      if (selection.length > 0) _this.WaitDelete = true;
      else _this.WaitDelete = false;
      _this.selection = [];
      selection.map((item) => {
        _this.selection.push(item);
      });
    },
    // 分页方法
    ChangePage(page) {
      this.paramsUser.Page = page;
      this.LoadingData();
    },
    // 每页条数--回调
    ChangePageSize(pagesize) {
      this.paramsUser.PageSize = pagesize;
      this.LoadingData();
    },
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.LoadingData();
        this.GetSelectedData();
      }
    },
  },
};
</script>
